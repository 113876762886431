import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QRCode } from 'lifetaggerQrCode';
//import { useTheme } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { Modal } from '@mui/material';
import Remove from './beacon.remove';
import { Add } from '@material-ui/icons';

function ShowQrCode(props) {
  const { qrCode, direction, size, justifyContent, deleteButton, history } =
    props;
  //const theme = useTheme();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
  );
  const [copySuccess, setCopySuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${qrCode.beaconId}`
        );
        const shortenedUrl = await response.text();
        setUrl(shortenedUrl);
      } catch (e) {
        console.log('error');
      }
    }
    fetchData();
  }, [props.qrCode]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    setOpen(false);
    history.push(qrCode.type === 'beacon' ? '/beacons' : '/qrcode');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  const handleSaveClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const addContent = () => {
    history.push(`/tags/create?lifecode=${qrCode.objectId}`);
  };

  const saveAsImage = (format) => {
    const downloadSize = 1000;
    const offScreenContainer = document.createElement('div');
    offScreenContainer.style.position = 'absolute';
    offScreenContainer.style.top = '-9999px';
    document.body.appendChild(offScreenContainer);

    ReactDOM.render(
      <QRCode
        value={url}
        size={downloadSize}
        ecLevel={'Q'}
        enableCORS={true}
        bgColor={qrCode.bgColor || '#f9f9f9'}
        fgColor={qrCode.fgColor || '#333'}
        logoImage={qrCode.image || '/images/Layer_2@3x.png'}
        eyeRadius={qrCode.eyeRadius || 0}
        logoWidth={40 * (downloadSize / 122)}
        qrStyle={qrCode.qrStyle || 'dots'}
      />,
      offScreenContainer,
      () => {
        setTimeout(() => {
          const canvas = offScreenContainer.querySelector('canvas');
          if (canvas) {
            const link = document.createElement('a');
            link.download = `${qrCode.name || 'qrcode'}-highres.${format}`;
            link.href =
              format === 'png'
                ? canvas.toDataURL('image/png')
                : canvas.toDataURL('image/jpeg', 0.92);
            link.click();
          }
          ReactDOM.unmountComponentAtNode(offScreenContainer);
          document.body.removeChild(offScreenContainer);
        }, 300);
      }
    );
  };

  return (
    <Card
      elevation={3}
      sx={{ padding: 3, borderRadius: '10px', backgroundColor: '#f8f9fa' }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Remove
          beacon={qrCode}
          handleClose={handleDelete}
          remove={props.remove}
        />
      </Modal>
      <Grid
        container
        direction={direction}
        justifyContent={justifyContent}
        spacing={2}
      >
        {qrCode.type !== 'beacon' && (
          <Grid item xs={6} ref={ref}>
            <QRCode
              value={url}
              ecLevel={'Q'}
              logoImage={qrCode.image || '/images/Layer_2@3x.png'}
              fgColor={qrCode.fgColor || '#333'}
              bgColor={qrCode.bgColor || '#f9f9f9'}
              eyeRadius={qrCode.eyeRadius || 0}
              size={size}
              qrStyle={qrCode.qrStyle || 'dots'}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {qrCode.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {qrCode.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
              <Grid item>
                {!deleteButton ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() =>
                      history.push('/qrcode/details/' + qrCode.beaconId)
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    startIcon={<DeleteForeverIcon />}
                    onClick={handleOpen}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Tooltip title={'Add Content To Code'} arrow>
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    startIcon={<Add />}
                    onClick={addContent}
                  >
                    Add Content{' '}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={copySuccess ? 'Copied!' : 'Copy URL'} arrow>
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    startIcon={<ContentCopyIcon />}
                    onClick={handleCopy}
                  >
                    Copy URL
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => saveAsImage('png')}>
                    Save as PNG
                  </MenuItem>
                  <MenuItem onClick={() => saveAsImage('jpg')}>
                    Save as JPG
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ShowQrCode;
