import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import Parse from 'parse';
import Upload from './upload.component';
import {
  Grid,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  Box,
  Slider,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import { QRCode } from 'lifetaggerQrCode';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const LifeCodeCreator = (props) => {
  const [beaconId] = useState(uuid().toUpperCase());
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('/images/Layer_2@3x.png');
  const [fgColor, setFgColor] = useState('#262626');
  const [bgColor, setBgColor] = useState('#f4f4f4');
  const [qrStyle, setQrStyle] = useState('dots');
  const [eyeRadius, setEyeRadius] = useState(0);
  const [type] = useState('qrcode');
  const [shortenedUrl, setShortenedUrl] = useState('');

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await fetch(
        `https://tinyurl.com/api-create.php?url=${process.env.REACT_APP_URL}/qrtags/${beaconId}`
      );
      const url = await response.text();
      setShortenedUrl(url);
    };

    fetchUrl();
  }, [beaconId]);

  const handleFileUpload = async (files) => {
    const parseImage = new Parse.File('image', files[0], files[0].type);
    await parseImage.save();
    setImage(parseImage.url());
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{ padding: '20px' }}
      spacing={2}
    >
      <Box
        sx={{ textAlign: 'center', padding: '40px 20px', marginBottom: '40px' }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: '#333',
          }}
        >
          Easily and Intelligently Connect, Engage, and Communicate
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#666',
          }}
        >
          Use the LifeTagger platform to create meaningful connections and drive
          deeper engagement. Start with a LifeCode – because everyone knows QR
          codes – and discover the power of contextual messaging to elevate
          every interaction.
        </Typography>
      </Box>
      <Grid item xs={12} md={10}>
        <Card
          style={{
            padding: '20px',
            backgroundColor: '#f4f4f4',
            borderRadius: '10px',
          }}
        >
          {/* Main Layout */}
          <Grid container spacing={4} alignItems="flex-start">
            {/* Controls Section */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6">Describe</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    size="small"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    size="small"
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Style
              </Typography>
              <Grid container spacing={2}>
                <Grid container item xs={6}>
                  <Grid item xs={12}>
                    <TextField
                      label="Image URL"
                      value={image}
                      onChange={(e) => setImage(e.target.value)}
                      fullWidth
                      size="small"
                      margin="normal"
                    />
                  </Grid>
                  <Grid>
                    <RadioGroup
                      row
                      value={qrStyle}
                      onChange={(e) => setQrStyle(e.target.value)}
                    >
                      <FormControlLabel
                        value="dots"
                        control={<Radio />}
                        label="Dots"
                      />
                      <FormControlLabel
                        value="squares"
                        control={<Radio />}
                        label="Squares"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <p>Eye Radius: {eyeRadius}</p>{' '}
                    <Slider
                      value={eyeRadius}
                      onChange={(e, newValue) => setEyeRadius(newValue)}
                      min={0}
                      max={50}
                      aria-labelledby="eye-radius-slider"
                      valueLabelDisplay="auto"
                    >
                      {' '}
                    </Slider>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Upload
                    handleFileUpload={handleFileUpload}
                    mediaType={['image/png', 'image/jpeg']}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Foreground Color</Typography>
                  <ChromePicker
                    color={fgColor}
                    onChange={(color) => setFgColor(color.hex)}
                    disableAlpha
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Background Color</Typography>
                  <ChromePicker
                    color={bgColor}
                    onChange={(color) => setBgColor(color.hex)}
                    disableAlpha
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Preview Section */}
            <Grid item xs={12} md={4}>
              <Box
                style={{
                  backgroundColor: '#f4f4f4',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  padding: '20px',
                  border: '1px solid #ddd',
                }}
              >
                <QRCode
                  value={shortenedUrl}
                  logoImage={image}
                  fgColor={fgColor}
                  bgColor={bgColor}
                  enableCORS
                  eyeRadius={eyeRadius}
                  size={200}
                  qrStyle={qrStyle}
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowRightAltIcon />}
            onClick={() =>
              props.saveBeacon({
                beaconId,
                name,
                description,
                type,
                image,
                fgColor,
                bgColor,
                qrStyle,
                shortenedUrl,
                eyeRadius,
              })
            }
            style={{
              marginTop: '20px',
              backgroundColor: '#b71c1c',
              color: '#fff',
              width: '100%',
            }}
          >
            Create Your LifeCode And Start Your Free Trial
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LifeCodeCreator;
