import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getProducts } from '../products/products.actions';
//import { useSpring, animated } from '@react-spring/web';
//import { styled } from '@mui/system';

import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import { Get as Show } from '../products/products.container';
import Paper from '@mui/material/Paper';
import { loadStripe } from '@stripe/stripe-js';
import { fetchCheckoutSession } from '../stripe/stripe.actions';
import Box from '@mui/material/Box';
import { Parse } from 'parse';
import * as R from 'ramda';
import LifeCodeCreator from '../ui/lifeCodeCreator.component';
import { createBeacon } from '../beacons/beacon.actions';

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

// const HeroSection = styled('div')(() => ({
//   background: 'linear-gradient(90deg, #f7f8fa, #ffffff)',
//   padding: '20px',
//   borderRadius: '10px',
//   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
// }));

// const HeroHeading = styled(Typography)(() => ({
//   fontFamily: '"Montserrat", sans-serif',
//   fontWeight: 'bold',
//   fontSize: '2.5rem',
//   lineHeight: 1.2,
//   backGround: 'linear-gradient(90deg, #ff6b6b, #ff8c42)',
//   webkitBackgroundClip: 'text',
//   webkitTextFillColor: 'transparent',
// }));

// const AnimatedText = () => {
//   // Animations
//   const fadeIn = useSpring({
//     from: { opacity: 0, transform: 'translateY(20px)' },
//     to: { opacity: 1, transform: 'translateY(0)' },
//     config: { duration: 800 },
//   });

//   const highlightBounce = useSpring({
//     from: { transform: 'scale(1)' },
//     to: { transform: 'scale(1.1)' },
//     config: { tension: 200, friction: 10 },
//     loop: { reverse: true },
//   });

//   return (
//     <HeroSection>
//       {/* Main heading animation */}
//       <animated.div style={fadeIn}>
//         <HeroHeading>
//           Too many QR codes in one place? Static codes that do nothing special?
//         </HeroHeading>
//       </animated.div>

//       {/* Subtext animation */}
//       <animated.div style={fadeIn}>
//         <Typography
//           variant="body1"
//           sx={{
//             fontFamily: '"Roboto", sans-serif',
//             fontSize: '1.2rem',
//             lineHeight: 1.8,
//             textAlign: 'left',
//             marginBottom: 2,
//           }}
//         >
//           It&apos;s time for an upgrade!
//           <br />
//           <br />
//           <animated.span style={highlightBounce}>
//             <Typography
//               component="span"
//               sx={{
//                 fontWeight: 'bold',
//                 fontSize: '1.5rem',
//                 color: '#1976D2', // Accent color
//               }}
//             >
//               LifeTagger
//             </Typography>
//           </animated.span>{' '}
//           is here to save the day with LifeCodes&mdash;QR codes that are
//           dynamic, smart, and just plain better.
//           <br />
//           <br />
//           As part of our{' '}
//           <Typography
//             component="span"
//             sx={{
//               fontWeight: 'bold',
//               color: '#388E3C', // Campaign-specific color
//             }}
//           >
//             Naughty QR Code Campaign
//           </Typography>
//           , we are giving you LifeTagger Pro features for the cost of LifeTagger
//           Lite&mdash;a{' '}
//           <Typography
//             component="span"
//             sx={{
//               fontWeight: 'bold',
//               color: '#FF9800', // Highlight savings
//             }}
//           >
//             66% savings
//           </Typography>
//           . Because you deserve better than basic, boring QR codes!
//         </Typography>
//       </animated.div>
//     </HeroSection>
//   );
// };

export function RegSpecial() {
  const dispatch = useDispatch();
  let products = useSelector((state) => state.products.data);
  let stripeSession = useSelector((state) => state.stripe.data);
  let [accountPlan, setAccountPlan] = useState(null);
  let [activeProducts, setActiveProducts] = useState(null);
  const [beacon, setBeacon] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const stripePromise = loadStripe(
    process.env[`REACT_APP_STRIPE_${process.env.REACT_APP_STAGE}_KEY`]
  );
  const [userParams, setUserParams] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [passwordError, setPasswordError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [phoneError, setPhoneError] = useState(true);

  const yearlyLabel = isYearly
    ? 'Yearly(Yay!!! you Get Two Months Free)'
    : 'Yearly(Toggle to get 2 Months Free)';
  const backgroundColors = ['#E6E2F7', '#F9F0E1', '#BFE2EB'];
  const requiredFields = [
    'name',
    'username',
    'email',
    'company',
    'phoneNumber',
  ];

  const accountPlanChanged = (plan) => {
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams.accountPlan = plan;
    setUserParams(newParams);
    setAccountPlan(plan);
    setPlanModalOpen(false);
    setModalOpen(true);
  };

  // useEffect(() => {
  //   const newParams = Object.assign({}, userParams);
  //   dispatch(fetchCheckoutSession(newParams));
  // }, [accountPlan]);

  useEffect(async () => {
    if (stripeSession) {
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: stripeSession.id });
    }
  }, [stripeSession]);

  const fieldChangeHandler = (e, field) => {
    setHasError(false);
    let newParams = {};
    if (userParams) {
      newParams = { ...userParams };
    }
    newParams[field] = e.target.value;
    setUserParams(newParams);
    if (
      passwordError ||
      emailError ||
      phoneError ||
      !hasRequiredFields(newParams)
    ) {
      setDisabled(true);
    } else {
      setDisabled();
    }
    // if (!disabled) {
    //   dispatch(fetchCheckoutSession(newParams));
    // }
  };

  const saveBeacon = (beac) => {
    console.log('iam saving the beacon ', beac);
    setBeacon(beac);
    setPlanModalOpen(true);
  };

  const hasRequiredFields = (obj) => {
    // Check if the object is null or undefined
    if (!obj) {
      return false;
    }

    // Check if all required fields have values in the object
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (!(field in obj) || obj[field] === '' || R.empty(obj[field])) {
        return false;
      }
    }

    // All required fields have values in the object
    return true;
  };

  const handlePasswordError = () => {
    if (userParams.password !== userParams.passwordConfirmation) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleClick = async () => {
    const user = new Parse.User();
    const newParams = Object.assign({}, userParams);
    newParams.status = 'trialing';
    delete newParams.passwordConfirmation;
    delete newParams.apiKey;
    newParams.accountPlan = accountPlan;
    const isFreePlan =
      accountPlan === 'price_1Qf1d5G7JJ7XmqTHu7j8GQv0' ||
      accountPlan === 'price_1Qf1MbG7JJ7XmqTHrJw4F8aE'
        ? true
        : false;

    if (isFreePlan) {
      await user.signUp(newParams, {});
    } else {
      await user.save(newParams, {});
    }

    try {
      // Call your backend to create the Checkout session.
      console.log('something isnt right.. ', isFreePlan);
      if (user && !isFreePlan) {
        newParams.trial = true;
        dispatch(fetchCheckoutSession(newParams));
      }

      if (beacon) {
        let updatedBeacon = {};
        updatedBeacon = { ...beacon };
        updatedBeacon.parent = user;
        updatedBeacon.followButtonText = 'Follow';
        console.log('this is the updated Beacon ', updatedBeacon);
        dispatch(createBeacon({ query: updatedBeacon }));
      }

      if (isFreePlan) {
        window.location.href = '/qrcode';
      }

      // When the customer clicks on the button, redirect them to Checkout.

      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };

  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };
  useEffect(() => {
    if (userParams && userParams.email) {
      validateEmail(userParams.email)
        ? setEmailError(false)
        : setEmailError(true);
    }

    if (userParams && userParams.phoneNumber) {
      validatePhone(userParams.phoneNumber)
        ? setPhoneError(false)
        : setPhoneError(true);
    }
  }, [userParams]);

  useEffect(() => {}, [accountPlan]);

  useEffect(() => {
    if (!products) {
      return;
    }
    const activeProducts = products.filter((product) => product.active);
    for (let i = 0; i < activeProducts.length; i++) {
      const product = activeProducts[i];
      product.color = backgroundColors[i];
    }

    activeProducts.sort(
      (a, b) =>
        a.prices.find((price) => price.id === a.default_price).unit_amount -
        b.prices.find((price) => price.id === b.default_price).unit_amount
    );

    setActiveProducts(activeProducts);
  }, [products]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={style}>
          <Card>
            <CardContent>
              <TextField
                required
                id="Name"
                label="Name: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'name')}
              />
              <TextField
                required
                id="username"
                label="Username: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'username')}
              />

              <FormHelperText
                error={emailError}
                style={{ visibility: emailError ? 'visible' : 'hidden' }}
              >
                The email you entered is invalid
              </FormHelperText>
              <TextField
                required
                id="email"
                label="Email: "
                fullWidth
                defaultValue=""
                type={'email'}
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'email')}
              />

              <FormHelperText
                error={phoneError}
                style={{ visibility: phoneError ? 'visible' : 'hidden' }}
              >
                The phone number you entered is invalid
              </FormHelperText>

              <TextField
                required
                id="phone"
                label="Phone: "
                defaultValue={''}
                type={'tel'}
                onChange={(change) => fieldChangeHandler(change, 'phoneNumber')}
              ></TextField>
              <TextField
                required
                id="password"
                label="Password: "
                type="password"
                fullWidth
                defaultValue=""
                margin="normal"
                variant="outlined"
                error={passwordError}
                name="password"
                onChange={(change) => fieldChangeHandler(change, 'password')}
                onBlur={handlePasswordError}
              />
              <TextField
                required
                id="password"
                label="Password Confirmation: "
                type="password"
                fullWidth
                defaultValue=""
                margin="normal"
                variant="outlined"
                onChange={(change) =>
                  fieldChangeHandler(change, 'passwordConfirmation')
                }
                error={passwordError}
                name="passwordConfirmation"
                onBlur={handlePasswordError}
              />
              <TextField
                required
                id="company"
                label="Company: "
                fullWidth
                defaultValue=""
                margin="normal"
                onChange={(change) => fieldChangeHandler(change, 'company')}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={disabled}
                onClick={handleClick}
              >
                Submit
              </Button>
            </CardContent>
            <CardContent>
              <FormHelperText
                error={hasError}
                style={{ visibility: hasError ? 'visible' : 'hidden' }}
              >
                {errorMessage}
              </FormHelperText>
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Modal
        open={planModalOpen}
        onClose={() => setPlanModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item justifySelf={'flex-start'} xs={6}>
            <Typography sx={{ margin: '12px' }} variant={'h4'}>
              Get Started by choosing a plan
            </Typography>
          </Grid>
          <FormControlLabel
            control={
              <Switch
                checked={isYearly}
                onChange={() => setIsYearly(!isYearly)}
              />
            }
            label={yearlyLabel}
          />
          {/* Hero Text Section */}
          {/* <Grid item xs={12} md={6}>
            <AnimatedText></AnimatedText>
          </Grid> */}

          {/* Product Display Section */}
          <Grid item xs={12}>
            {activeProducts && activeProducts.length > 0 && (
              <Grid container spacing={4} justifyContent={'center'}>
                {activeProducts.map(
                  (product, key) =>
                    product.active && (
                      <Grid item xs={3} key={key}>
                        <Show
                          backgroundColor={
                            backgroundColors[key % backgroundColors.length]
                          }
                          setPlan={accountPlanChanged}
                          isYearly={isYearly}
                          product={product}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>

      <Paper sx={{ padding: '32px' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <LifeCodeCreator saveBeacon={saveBeacon} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
